import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import StockNinjaImg from "../../assets/img/projects/stock_ninja.png";
import AromaSpices from "../../assets/img/projects/aroma_spices.png";
import BakeBench from "../../assets/img/projects/bake_bench.png";
import BatchelorsTiffun from "../../assets/img/projects/batchelors_tiffun.png";
import MobileBazzar from "../../assets/img/projects/mobile_bazar.png";
import SpotBuy from "../../assets/img/projects/spot_buy.png";
import SpotRental from "../../assets/img/projects/spot_rental.png";
import AddImage2 from "../../assets/img/header2.png";

export default function Projects() {
  const scrollToComponent = () => {
    const element = document.getElementById('contact');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Projects</h1>
            <p className="font13">
            At TFP Technology, we specialize in bringing ideas to reality with innovative, impactful projects in web and mobile development.<br/>From custom designed websites to powerful mobile applications. Our portfolio offers solutions built to meet unique customer needs and provide a smooth user experience. <br/>Dive deeper to explore the innovative work we're doing to empower businesses with technology.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={StockNinjaImg}
                title="Stock Ninja"
                text="Stock Ninja offers expert guidance and tools for online trading, helping you navigate the stock market with confidence. Whether you're a beginner or an experienced trader, we provide insights to optimize your investment strategies."
                
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={BakeBench}
                title="Bake Bench"
                text="
                Bake Bench specializes in creating delicious, homemade cakes and pastries that are both sugar-free and egg-free, without compromising on taste. Indulge in our guilt-free, mouthwatering treats made with love and care."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={AromaSpices}
                title="Aroma Spices"
                text="Aroma Spices is a locally trusted brand that offers a premium range of freshly sourced spices, enhancing the flavor and aroma of every dish. Committed to quality, Aroma Spices brings the essence of authentic flavors to your kitchen."
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={MobileBazzar}
                title="Mobile Bazzar"
                text="MobileBazzar offers a wide selection of the latest premium smartphones, including the newest iPhones, alongside high-quality refurbished mobiles at unbeatable prices. Discover the perfect phone for your needs, whether brand new or like-new!"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={SpotBuy}
                title="Spot Buy"
                text="Spot Buy is your trusted platform for buying and selling second-hand vehicles, available through both our mobile and web apps. Easily connect with buyers and sellers to find quality pre-owned vehicles at the best prices."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={BatchelorsTiffun}
                title="Batchelor's Tiffun"
                text="Batchelors Tiffin is a personalized tiffin service app that lets you customize your meals every day, ensuring a fresh and satisfying lunch experience. Enjoy the freedom to choose your ingredients and flavors, tailored to your tastes and dietary needs."
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" action={() => alert("clicked")} />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A few words about TFP Technology</h4>
              <h2 className="font40 extraBold">A Study of Creativity</h2>
              <p className="font12">
              At TFP Technology, creativity fuels our vision and enthusiasm propels our work. We believe in turning bold ideas into dynamic digital solutions that resonate with audiences. With a passionate team, we craft custom websites, mobile applications, and software that bring brands to life. Our commitment to innovation ensures every project is a step toward redefining possibilities for our clients.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Get Started" action={scrollToComponent} />
                </div>
               
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
