import React from "react";
import styled from "styled-components";
// Assets

import Website from "../../assets/img/services/website_icon.png";
import WebApp from "../../assets/img/services/app_development.png";
import MobileApp from "../../assets/img/services/mobile_app.png";
import DigitalMarketing from "../../assets/img/services/digital_marketing.png";

export default function ServiceBox({ icon, title, subtitle }) {
  let getIcon;

  switch (icon) {
    case "website":
      getIcon = Website;
      break;
    case "webApp":
      getIcon = WebApp;
      break;
    case "mobileApp":
      getIcon = MobileApp ;
      break;
    case "digitalMarketing":
      getIcon = DigitalMarketing;
      break;
    default:
      getIcon = Website
      break;
  }

  return (
    <Wrapper className="flex flexColumn">
      <IconStyle><img src={getIcon} style={{width:"50px", height:"50px"}}/></IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 30px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;

