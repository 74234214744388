import React from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";
import { useState } from "react";
import emailjs from "emailjs-com";

import "../../style/Contact.css";
export default function Contact() {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isSent, setIsSent] = useState(false); // To track if the form is submitted
  const [showPopup, setShowPopup] = useState(false); // To control popup visibility

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Send email using EmailJS
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Don't submit if validation fails
    }

    emailjs
      .sendForm(
        "service_mtkfjmt",
        "template_6mvhxnq",
        e.target,
        "eCF3Z5BvNPrXxXj7d"
      )
      .then((result) => {
        console.log(result.text);
        setIsSent(true); // Show a success message when email is sent
        setFormData({ name: "", email: "", message: "" }); // Clear form fields
      })
      .catch((error) => {
        console.error(error.text);
      });
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!formData.fname) {
      formErrors.fname = "First Name is required.";
      isValid = false;
    }

    if (!formData.lname) {
      formErrors.lname = "Last Name is required.";
      isValid = false;
    }

    if (!formData.email) {
      formErrors.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Please enter a valid email address.";
      isValid = false;
    }

    if (!formData.message) {
      formErrors.message = "Message is required.";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  return (
    <Wrapper id="contact">
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <h3>Message Sent Successfully!</h3>
            <p>Our team will connect with you soon.</p>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font13">
              Ready to bring your ideas to life? <br />
              Reach out to us to discuss how we can create innovative solutions
              tailored to your business needs.
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form onSubmit={handleSubmit}>
                <label className="font13">First name:</label>
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  className="font20 extraBold"
                  value={formData.fname}
                  onChange={handleChange}
                  required
                />
                {errors.fname && (
                  <p className="error-message">{errors.fname}</p>
                )}
                <label className="font13">Last name:</label>
                <input
                  type="text"
                  id="lname"
                  name="lname"
                  className="font20 extraBold"
                  value={formData.lname}
                  onChange={handleChange}
                  required
                />
                {errors.lname && (
                  <p className="error-message">{errors.lname}</p>
                )}
                <label className="font13">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="font20 extraBold"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && (
                  <p className="error-message">{errors.email}</p>
                )}
                <label className="font13">Subject:</label>
                <textarea
                  rows="4"
                  cols="50"
                  type="text"
                  id="message"
                  name="message"
                  className="font20 extraBold"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
                {errors.message && (
                  <p className="error-message">{errors.message}</p>
                )}

              

                <SumbitWrapper className="flex">
                  <ButtonInput
                    type="submit"
                    value="Send Message"
                    className="pointer animate radius8"
                    style={{

                       
                        width: "220px",
                        height: "4vmax",
                        minHeight: "50px",
                        borderRadius: "15px",
                        backgroundColor: "#7620ff",
                        border: "1px solid #7620ff",
                        color: "#fff",
                        outline: "none",
                        padding: "0",
                        textAlign: "center",
                        fontSize: "17px",
                     
                    }}
                  />
                </SumbitWrapper>
              </Form>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div
                style={{ width: "50%" }}
                className="flexNullCenter flexColumn"
              >
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" />
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={ContactImg3} alt="office" className="radius6" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;

  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px;
  align-self: flex-end;
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
