import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LOGOImg from "../../assets/img/tfp_white_logo.png";
import { BsInstagram } from "react-icons/bs";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import { FaLinkedin } from "react-icons/fa";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            <div>
              <Link
                className="flexCenter animate pointer"
                to="home"
                smooth={true}
                offset={-80}
              >
                <img src={LOGOImg} width={50} style={{ marginTop: "-10px" }} />

                <h1
                  className="font20 extraBold whiteColor"
                  style={{ marginLeft: "15px" }}
                >
                  TFP Technologies
                </h1>
              </Link>

              <div>
                <br />
                <h1 className="font20 extraBold whiteColor">Contact</h1>
                <ContactInfo>
                  Mobile Number :+91 9074644022, +91 8815298130
                </ContactInfo>
                <ContactInfo>Email : technologiestfp@gmail.com</ContactInfo>
              </div>
            </div>

            <div>
              <IconDiv>
                <a
                  href="https://www.linkedin.com/company/tfptechnologies/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={24} />
                </a>
                <a
                  href="https://www.instagram.com/tfptechnologies"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsInstagram size={24} />
                </a>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <FaSquareFacebook size={24} />
                </a>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <FaTwitter size={24} />
                </a>
                <a
                  href="https://wa.me/+918815298130?text=Hii`} "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ImWhatsapp size={24} />
                </a>
              </IconDiv>

              <StyleP className="whiteColor font13">
                © {getCurrentYear()} -{" "}
                <span className="purpleColor font13">TFP Technologies</span> All
                Right Reserved
              </StyleP>
            </div>

            <Link
              className="whiteColor animate pointer font13"
              to="home"
              smooth={true}
              offset={-80}
            >
              Back to top
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;

const IconDiv = styled.div`
  color: white;
  width: auto;
  height: 40px;
  cursor: pointer;
  display: flex;
  gap: 10px;

  a {
    text-decoration: none;
    color: white;
  }
`;

const ContactInfo = styled.div`
  color: white;
  display: block;
  cursor: pointer;
`;
