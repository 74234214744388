import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/front_img.png";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import { useEffect } from 'react';
export default function Header() {
  // useEffect(() => {
  //   typeWriter();
  // }, []); //

  const scrollToComponent = () => {
    const element = document.getElementById('contact');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60">We are </h1>
          <div id ="typewriter" className="extraBold font40 purpleColor" style={{minHeight:"4.5rem"}}></div>
          <HeaderP className="font13 semiBold">
          TFP Technology is a dynamic software company and digital agency dedicated to delivering innovative tech solutions. We specialize in custom software development, digital marketing, and design services to empower businesses and drive growth in the digital age.
          </HeaderP>
          <BtnWrapper>
            <FullButton title="Get Started" action={scrollToComponent} />
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="office" style={{zIndex: 9}} />
          {/* <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>Friends, such as we desire, are dreams and fables. Friendship demands the ability to do without it.</em>
              </p>
              <p className="font13 orangeColor textRight" style={{marginTop: '10px'}}>Ralph Waldo Emerson</p>
            </div>
          </QuoteWrapper> */}
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>
  );
}



const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`; const words = ["Digital Agency!", "Software Company!"];
let wordIndex = 0;
let charIndex = 0;
let currentWord = '';
let isDeleting = false;
const speed = 100; // Speed of typing
const pauseTime = 1500; // Pause before deleting in ms

function typeWriter() {
  const typewriter = document.getElementById("typewriter");

  if (wordIndex >= words.length) wordIndex = 0; // Loop over the words

  currentWord = words[wordIndex];

  // Manage the cursor element
  let cursor = document.querySelector("#typewriter .cursor");
  if (!cursor) {
    cursor = document.createElement("span");
    cursor.classList.add("cursor");
    typewriter.appendChild(cursor);
  }

  // Determine whether we are typing or deleting
  if (isDeleting) {
    typewriter.innerHTML = currentWord.substring(0, charIndex--)+ "<span class='cursor'></span>";; // Deleting
    if (charIndex < 0) { // Finish deleting, move to next word
      isDeleting = false;
      wordIndex++;
      setTimeout(typeWriter, 300); // Short pause before typing next word
    } else {
      setTimeout(typeWriter, speed / 2); // Faster deleting speed
    }
  } else {
    typewriter.innerHTML = currentWord.substring(0, charIndex++) + "<span class='cursor'></span>"; // Typing with cursor
    if (charIndex > currentWord.length) { // Finish typing, start deleting
      isDeleting = true;
      setTimeout(typeWriter, pauseTime); // Pause before deleting
    } else {
      setTimeout(typeWriter, speed); // Typing speed
    }
  }
}

// Initialize the typewriter effect when the page loads
document.addEventListener("DOMContentLoaded", function() {
  typeWriter(); // Start the effect
});